import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Glitch from "./glitch/";
import Blob from "./blob/";
import Party from "./party/";
import './App.css';

function BasicExample() {
  return (
    <Router>
      <div id="menu">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/glitch">Glitch</Link>
          </li>
          <li>
            <Link to="/blob">Blob</Link>
          </li>
          <li>
            <Link to="/party">Party</Link>
          </li>
        </ul>
      </div>
      <Route exact path="/" component={Home} />
      <Route path="/glitch" component={Glitch} />
      <Route path="/blob" component={Blob} />
      <Route path="/party" component={Party} />
    </Router>
  );
}

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

export default BasicExample;
